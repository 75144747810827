import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './permission'

Vue.config.productionTip = false

// 引入jQuery、bootstrap
import $ from 'jquery'
import 'bootstrap'

// 引入bootstrap样式
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import './assets/css/style.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'waypoints/lib/noframework.waypoints.min.js'

// 全局注册 $
Vue.prototype.$ = $

// 路由切换时手动埋点
router.beforeEach(async (to, from, next) => {
  if (to.path) {
     if (window._hmt) {
          window._hmt.push(['_trackPageview', '/#' + to.fullPath]);
      }
  }
  next(); 
}); 

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


import {getOSType, getDeviceType} from "@/utils/util";
import {postAction} from "@/utils/request";

/**
 * 发送访问数据
 * @param from
 * @param to
 */
export const insertVisitRecord = async (from, to) => {
    const data = await getPageViewRecordData(from, to);
    postAction('/api/visit/insertVisitData', data)
};

/**
 * 获取pv记录data
 * @param id
 * @param to
 * @param from
 * @param args
 * @returns null || {{deviceType: number, srcItemType: (number|null), itemType: number, srcItemId: null, ip, weixinid: (*|null), userId: (null|*), deviceId: string, url: (*|null), itemId: null, actionType: string | number, srcUserId: (*|number), srcPageId: (*|number), siteId: number, srcTitle: (*|null), id: string, isMobile: number, pageScope: (*|number)} || null}
 */
const getPageViewRecordData = async (to, from, args = {}) => {
    const {eventId, eventName, eventStep, srcEventRecordId, srcitemid, srcitemtype, srcpath } = args
    const _eventCode = 'PageView';
    const _eventType = 0
    let itemId = '1120'
    let itemType = 7
    let itemUrl = location.host
    let sourceId = null
    let sourceType = null
    let srcUserId = null
    let srcUserType = null
    const fromParams = from.params
    const fromKey = (from.meta && from.meta.dataKey) || null
    let srcItemId = sourceId ? sourceId : srcitemid ? srcitemid : (from.meta && from.meta.dataId) ? from.meta.dataId : fromKey ? fromParams[fromKey] || null : null
    const srcItemType = sourceType ? Number(sourceType) : srcitemtype ? srcitemtype : (from.meta && from.meta.pageViewType) || null
    const srcItemUrl = srcpath ? srcpath : from.fullPath
    const deviceType = await getDeviceType(itemUrl || srcItemUrl)
    // itemId,srcItemId适配小程序传参
    if (itemId && typeof itemId == 'string') {
        if (itemId.indexOf('||') > -1) {
            const _itemId = itemId.split('||')[0]
            if (_itemId) {
                itemId = _itemId
            }
        }
    }
    if (srcItemId && typeof itemId == 'string') {
        if (srcItemId.indexOf('||') > -1) {
            const _srcItemId = srcItemId.split('||')[0]
            if (_srcItemId) {
                srcItemId = _srcItemId
            }
        }
    }
    let _sTask = null
    let _sTaskType = 0
    let _sTaskTitle = null
    return {
        userId: null,
        username: null,
        userType: null,
        deviceId: null,
        deviceName: navigator.userAgent ? navigator.userAgent.toLowerCase() : null,
        osType: getOSType(),
        deviceType: deviceType,
        eventId: eventId,
        eventType: _eventType,
        eventCode: _eventCode,
        eventStep: eventStep || 0,
        eventName: eventName || '页面浏览',
        itemId: itemId,
        itemType: itemType,
        itemUrl: itemUrl,
        itemTitle: 'CHINA VALVE (HANGZHOU) 2024',
        srcItemId: srcItemId,
        srcItemType: srcItemType,
        srcItemUrl: srcItemUrl,
        srcChannel: 0,
        srcEventRecordId: srcEventRecordId,
        srcUserId: srcUserId,
        srcUserType: srcUserType,
        isFullView: 0,
        parameterContent: null,
        siteId: 1,
        sTask: _sTask,
        sTaskType: _sTaskType,
        sTaskTitle: _sTaskTitle
    }
}
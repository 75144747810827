import router from './router'
import { insertVisitRecord } from "@/api/visit";

router.afterEach( (to, from) => {
  // let FRONT_BASE = `${location.protocol}//${location.host}`
  // let url = FRONT_BASE + to.fullPath
  insertVisitRecord(to, from);

})


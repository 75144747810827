/**
 * Easy selector helper function
 */
export const selectEl = (el, all = false) => {
    el = el.trim()
    if (all) {
        return [...document.querySelectorAll(el)]
    } else {
        return document.querySelector(el)
    }
}

/**
 * Easy on scroll event listener
 */
export const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener)
}

/**
 * Easy event listener function
 */
export const onBackCall = (type, el, listener, all = false) => {
    let selectedEl = selectEl(el, all)
    if (selectedEl) {
        if (all) {
            selectedEl.forEach(e => e.addEventListener(type, listener))
        } else {
            selectedEl.addEventListener(type, listener)
        }
    }
}

/**
 * 根据域名获取接口
 * @returns {string|*}
 */
export function getCurrentApiUrl() {
    const domain = document.domain
    if (domain === "global.drvoice.cn") {
        return "https://globaljapi.drvoice.cn/drvoice/";
    }else {
        return process.env.VUE_APP_API_BASE_URL;
    }
}

/**
 *
 * @returns {number}
 */
export function getCurrentSiteId() {
    return 1
}

/**
 * 操作系统类型
 * @type {{Windows: number, IOS: number, Mac: number, Android: number}}
 */
export const OS_TYPE = {
    Unknown: 5,
    Windows: 0,
    Mac: 1,
    Android: 2,
    IOS: 3,
    Linux: 4
}

/**
 * 设备类型
 * @type {{App: number, PC: number, Weixin: number, Server: number, Web: number, Miniprogram: number}}
 */
export const DEVICE_TYPE = {
    Unknown: -1,
    App: 0,
    Web: 1,
    Weixin: 2,
    Miniprogram: 3,
    Server: 4,
    PC: 5
}

/**
 * 获取操作系统类型
 */
export const getOSType = () => {
    //首先获取设备类型
    const agent = navigator.userAgent.toLowerCase()
    if (!agent) return OS_TYPE.Unknown
    if (agent.indexOf('windows') > -1) {
        return OS_TYPE.Windows
    } else if (agent.indexOf('iphone') > -1 || agent.indexOf('ipad') > -1) {
        return OS_TYPE.IOS
    } else if (agent.indexOf('mac') > -1) {
        return OS_TYPE.Mac
    } else if (agent.indexOf('android') > -1) {
        return OS_TYPE.Android
    } else if (agent.indexOf('linux x86_64') > -1) {
        return OS_TYPE.Linux
    }
    return OS_TYPE.Unknown
}

/**
 * 获取设备类型
 */
export const getDeviceType = async (itemUrl) => {
    const agent = navigator.userAgent.toLowerCase()
    if ((itemUrl && itemUrl.indexOf('webview=1') > -1)) {
        return DEVICE_TYPE.App
    } else if (itemUrl && itemUrl.indexOf('||') > -1) {
        return DEVICE_TYPE.Miniprogram
    }else if (agent.indexOf('micromessenger') !== -1) {
        return DEVICE_TYPE.Weixin
    }
    return DEVICE_TYPE.Web
}
